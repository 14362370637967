import * as React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next'
import HeaderPrimary from "../components/headers/header-primary";
import NumberedCards from "../components/homepage/numbered-cards";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import BgImage from "../assets/images/headers/careers.png"
import { graphql } from 'gatsby'
import TextTwoCols from "../components/global/text-two-cols";
import BgDivider from "../components/global/bg-divider";
import CommuteIcon from "../assets/images/careers/commute.svg"
import EquipmentIcon from "../assets/images/careers/equipment.svg"
import HealthIcon from "../assets/images/careers/health.svg"
import HolidaysIcon from "../assets/images/careers/holidays.svg"
import OfficesIcon from "../assets/images/careers/offices.svg"
import WellnessIcon from "../assets/images/careers/wellness.svg"


const Carrers = () => {
  const { t } = useTranslation()
  const headerContent = {
    title: `${t('careers.header.title')}`,
    text: `${t('careers.header.text')}`,
    buttonText: `${t('buttons.seeJobs')}`,
    buttonUrl: '/our-work',
    imageUrl: BgImage,
  };

  const textTwoColContent = {
    title: `${t('aboutUs.about.headlineSecond')}`,
    textLeftFirst: `${t('aboutUs.about.firstText')}`,
    textLeftSecond: `${t('aboutUs.about.secondText')}`,
    textRightFirst: `${t('aboutUs.about.fourthText')}`,
    textRightSecond: `${t('aboutUs.about.fifthText')}`,
  };

  const perksContent = [
    {
      title: `${t('careers.numberedCards.cards.health.title')}`,
      text: `${t('careers.numberedCards.cards.health.text')}`,
      icon: <HealthIcon />
    },
    {
      title: `${t('careers.numberedCards.cards.holidays.title')}`,
      text: `${t('careers.numberedCards.cards.holidays.text')}`,
      icon: <HolidaysIcon />
    },
    {
      title: `${t('careers.numberedCards.cards.wellness.title')}`,
      text: `${t('careers.numberedCards.cards.wellness.text')}`,
      icon: <WellnessIcon />
    },
    {
      title: `${t('careers.numberedCards.cards.offices.title')}`,
      text: `${t('careers.numberedCards.cards.offices.text')}`,
      icon: <OfficesIcon />
    },
    {
      title: `${t('careers.numberedCards.cards.equipment.title')}`,
      text: `${t('careers.numberedCards.cards.equipment.text')}`,
      icon: <EquipmentIcon />
    },
    {
      title: `${t('careers.numberedCards.cards.cummute.title')}`,
      text: `${t('careers.numberedCards.cards.cummute.text')}`,
      icon: <CommuteIcon />
    },
  ];

  const numberedCardsContent = {
    title: `${t('careers.numberedCards.headline')}`,
    cards: [
      {
        title: `${t('careers.numberedCards.cards.first.title')}`,
        text: `${t('careers.numberedCards.cards.first.text')}`,
      },
      {
        title: `${t('careers.numberedCards.cards.second.title')}`,
        text: `${t('careers.numberedCards.cards.second.text')}`,
      },
      {
        title: `${t('careers.numberedCards.cards.third.title')}`,
        text: `${t('careers.numberedCards.cards.third.text')}`,
      },
      {
        title: `${t('careers.numberedCards.cards.fourth.title')}`,
        text: `${t('careers.numberedCards.cards.fourth.text')}`,
      },
    ]
  }

  return (
    <Layout>
      <Seo title="Careers" />
      <HeaderPrimary headerContent={headerContent} />
      <BgDivider />
      <TextTwoCols textTwoColContent={textTwoColContent} />
      <NumberedCards numberedCardsContent={numberedCardsContent} />
    </Layout>
  );
};

export default Carrers;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`